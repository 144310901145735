import { useEffect } from "react";

function App() {
  const sendMessageToVeots = () => {
    setTimeout(() => {
       //verify/72876093551926240608sam146400001

      

      
    }, 500);
  };

  return (
    <div />
  );
}

export default App;
